<template>
  <button
    type="button"
    class="flex items-center gap-x-2 link-green-dots cursor-pointer text-[16px] mb-4 lg:mb-0"
    @click.stop.prevent="openAvailabilityDialog"
  >
    <IconsProductAvailabilityCheck v-if="showIcon" class="h-2.25 w-3.5" />
    <slot> {{ t('Є в наявності в магазинах') }} </slot>

    <AppDialogsAvailability
      v-model:show="showAvailabilityDialog"
      :variant-code="variant.code"
    />
  </button>
</template>
<script setup>
const { t } = useI18n()
defineProps({
  variant: {
    type: Object,
    default: () => {}
  },
  showIcon: {
    type: Boolean,
    default: false
  }
})
const showAvailabilityDialog = ref(false)

const openAvailabilityDialog = () => {
  showAvailabilityDialog.value = true
}
</script>

<style land="postcss" scoped>
.shipping-item--description {
  @apply text-green-ibis ml-auto;
}
button.shipping-item--description {
  @apply underline decoration-dashed underline-offset-4;
}
</style>
